import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Song from "./Song";
import SongListDatabase from "../resource/songlist";

function SongCatalogue() {
    const songListCatalogue = Object.keys(SongListDatabase).sort().map((keyName, idx) => (
        <Song title={SongListDatabase[keyName]} songId={keyName} index={idx} />
    ));

    return (
        <Container className="p-2">
            <Accordion defaultActiveKey="0">
                {songListCatalogue}
            </Accordion>
        </Container>
    );
}

export default SongCatalogue;
