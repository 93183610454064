import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useRouteError } from "react-router-dom";

function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Container className="error-page py-5">
            <Card>
                <Card.Body>
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occured.</p>
                    <p>
                        <i>{error.statusText || error.message}</i>
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ErrorPage;