import { Outlet } from 'react-router-dom';
import HeaderContainer from './component/HeaderContainer';

function App() {
  return (
    <div>
      <HeaderContainer />
      <Outlet />
    </div>
  );
}

export default App;
