import Accordion from "react-bootstrap/Accordion";
import Song from "./Song";

function SongList({ list }) {
    const songSummary = list.map((songInfo, index) =>
        <Song title={songInfo.title} songId={songInfo.id} key={index} index={index} />
    );

    return (
        <Accordion defaultActiveKey="0">
            {songSummary}
        </Accordion>
    );
}

export default SongList;