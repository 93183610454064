import Accordion from "react-bootstrap/Accordion";
import yaml from 'js-yaml';
import axios from 'axios';
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

function Song({ title, songId, index }) {
    let fileLoc = "./songs/" + songId.toLowerCase().replaceAll(' ','_') + ".yaml";
    let songInfo;
    let lyricContent;
    let songTitle;

    const [songData, setSongData] = useState([]);

    useEffect(() => {
        axios.get(fileLoc)
        .then((res) => setSongData(res.data))
        .catch(err => {return;} )
    }, [ fileLoc ]);

    try {
        if (songData) {
            songInfo = yaml.load(songData);
            songTitle = songInfo.title;
            lyricContent = songInfo.lyrics.replace(/\n/g, "<br/>");
        }
    } catch (err) { }

    const songInfoContent = () => {
        if (lyricContent !== undefined) {
            return (
                <Container className="mb-1">
                    <h3>{songInfo.title}</h3>
                    <div dangerouslySetInnerHTML={{__html: lyricContent}}></div>
                </Container>
            );
        } else {
            return <span>No lyrics</span>;
        }
    };

    return (
        <Accordion.Item eventKey={index}>
            <Accordion.Header>{songTitle}</Accordion.Header>
            <Accordion.Body>
                {songInfoContent()}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default Song;