import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Card from 'react-bootstrap/Card';
import SongList from './SongList';
import axios from 'axios';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

function Schedules() {
  const scheduleLoc = "./schedule.json";
  const [schedule, setSchedule] = useState([]);
  const { page } = useParams();
  const navigate = useNavigate();

  let currentPage;
  if (page) {
    currentPage = parseInt(page);
  } else {
    currentPage = 1;
  }

  useEffect(() => {
      axios.get(scheduleLoc)
      .then((res) => setSchedule(res.data))
      .catch(err => {return;} )
  }, []);

  const scheduleData = schedule
    .filter((_, index) => index === (currentPage-1))
    .map((data) => {
      return (
          <Card className="mb-2" key={data.date}>
              <Card.Body>
                  <Card.Title>{data.date}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{data.scheduleTime}</Card.Subtitle>
                  <Card.Body>
                      <SongList list={data.lineup} />
                  </Card.Body>
              </Card.Body>
          </Card>
      )
    });
  
  const changePage = (pageNumber) => {
    if (pageNumber < 1) pageNumber = 1;
    if (pageNumber > schedule.length) return;

    let path = "/" + pageNumber;
    navigate(path);
  }

  let prevPages = []
  let prevPadding =
    (currentPage < 3) ? (3 - currentPage)
      : (currentPage > schedule.length - 2)
        ? 4 - (schedule.length - currentPage) : 2;
  for (let x = currentPage - prevPadding; x > 0 && x < currentPage; x++) {
    prevPages.push(<Pagination.Item onClick={()=>changePage(x)}>{x}</Pagination.Item>);
  }

  let nextPages = []
  let nextPadding = currentPage < 3 ? 5 - currentPage : 2;
  for (let x = currentPage + 1; x <= (currentPage + nextPadding) && x <= schedule.length; x++) {
    nextPages.push(<Pagination.Item onClick={()=>changePage(x)}>{x}</Pagination.Item>);
  }

  const pagination = (
    <Pagination>
      <Pagination.First onClick={() => changePage(1)}/>
      <Pagination.Prev onClick={() => changePage(currentPage - 1)} />
      {prevPages}
      <Pagination.Item active>{currentPage}</Pagination.Item>
      {nextPages}
      <Pagination.Next onClick={() => changePage(currentPage + 1)} />
      <Pagination.Last onClick={() => changePage(schedule.length)} />
    </Pagination>
  );

  return (
    <Container className="p-2">
      {scheduleData}
      {pagination}
    </Container>
  );
}

export default Schedules;
